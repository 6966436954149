import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import _join from 'lodash/join';
import _capitalize from 'lodash/capitalize';

import styles from './avatar.module.scss';

const Avatar = ({ author, size }) => (
  <StaticQuery
    query={graphql`
      query {
        em: file(relativePath: { eq: "avatar/em_avi.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 80, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        b: file(relativePath: { eq: "avatar/b_avi.png" }) {
          childImageSharp {
            fluid(maxWidth: 80, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const { em, b } = data;
      const avatarClassNames = [styles.avatar, styles[author], styles[size]];
      const fluidImage = author === 'blake' ? b.childImageSharp.fluid : em.childImageSharp.fluid;
      return (
        <div className={_join(avatarClassNames, ' ')}>
          <Img fluid={fluidImage} alt={`${_capitalize(author)} - Austism Parenting Journey`} />
        </div>
      );
    }}
  />
);

Avatar.propTypes = {
  author: PropTypes.oneOf(['blake', 'emily']).isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
};

export default Avatar;
